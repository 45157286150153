/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

@import "./imports.scss";

@font-face {
    font-family: "FS Me";
    src: url("~/src/styles/fonts/FS Me-Regular.otf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "FS Me";
    src: url("~/src/styles/fonts/FS Me-Italic.otf");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "FS Me";
    src: url("~/src/styles/fonts/FS Me-Bold.otf");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "FS Me";
    src: url("~/src/styles/fonts/FS Me-Bold Italic.otf");
    font-weight: bold;
    font-style: italic;
}

body {
    @media (prefers-color-scheme: dark) {
        --ion-text-color: #c7c7c7;
        --ion-background-color: #1e1e1e;
    }

    @media (prefers-color-scheme: light) {
        --ion-text-color: #4c4c4c;
        --ion-background-color: #fcfcfc;
    }
}

a {
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

ion-header {
    box-shadow: none !important;
}

.firebaseui-link {
    color: #bdbdbd;
    text-decoration: none;
}