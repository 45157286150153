// For information on how to create your own theme, please see:
// http://ionicframework.com/docs/theming/

// Color variables
:root {
	--ion-color-primary: #2563b3;
	--ion-color-primary-rgb: 37, 99, 179;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255, 255, 255;
	--ion-color-primary-shade: #21579e;
	--ion-color-primary-tint: #3b73bb;

	--ion-color-secondary: #2ca0bf;
	--ion-color-secondary-rgb: 44, 160, 191;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255, 255, 255;
	--ion-color-secondary-shade: #278da8;
	--ion-color-secondary-tint: #41aac5;

	--ion-color-tertiary: #f39740;
	--ion-color-tertiary-rgb: 243, 151, 64;
	--ion-color-tertiary-contrast: #000000;
	--ion-color-tertiary-contrast-rgb: 0, 0, 0;
	--ion-color-tertiary-shade: #d68538;
	--ion-color-tertiary-tint: #f4a153;

	--ion-color-success: #6dba82;
	--ion-color-success-rgb: 109, 186, 130;
	--ion-color-success-contrast: #FFFFFF;
	--ion-color-success-contrast-rgb: 255, 255, 255;
	--ion-color-success-shade: #60a472;
	--ion-color-success-tint: #7cc18f;

	--ion-color-warning: #eed172;
	--ion-color-warning-rgb: 238, 209, 114;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0, 0, 0;
	--ion-color-warning-shade: #d1b864;
	--ion-color-warning-tint: #f0d680;

	--ion-color-danger: #f3727d;
	--ion-color-danger-rgb: 243, 114, 125;
	--ion-color-danger-contrast: #000000;
	--ion-color-danger-contrast-rgb: 0, 0, 0;
	--ion-color-danger-shade: #d6646e;
	--ion-color-danger-tint: #f4808a;

	--ion-color-light: #d5d5d5;
	--ion-color-light-rgb: 213, 213, 213;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #bbbbbb;
	--ion-color-light-tint: #d9d9d9;

	--ion-color-medium: #9c9c9c;
	--ion-color-medium-rgb: 156, 156, 156;
	--ion-color-medium-contrast: #000000;
	--ion-color-medium-contrast-rgb: 0, 0, 0;
	--ion-color-medium-shade: #898989;
	--ion-color-medium-tint: #a6a6a6;

	--ion-color-dark: #2f2f2f;
	--ion-color-dark-rgb: 47, 47, 47;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255, 255, 255;
	--ion-color-dark-shade: #292929;
	--ion-color-dark-tint: #444444;
}

// Application variables
:root {
	--ion-font-family: "FS ME";
}