/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";
/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";
@import '~firebaseui/dist/firebaseui.css';
/**
* Ionic Dark Mode
* -----------------------------------------------------
* For more info, please see:
* https://ionicframework.com/docs/theming/dark-mode
*/
/* @import "@ionic/angular/css/palettes/dark.always.css"; */
/* @import "@ionic/angular/css/palettes/dark.class.css"; */
/* @import '@ionic/angular/css/palettes/dark.system.css'; */
@import '@ionic/angular/css/palettes/dark.system.css';
@font-face {
  font-family: "FS Me";
  src: url("~/src/styles/fonts/FS Me-Regular.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "FS Me";
  src: url("~/src/styles/fonts/FS Me-Italic.otf");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "FS Me";
  src: url("~/src/styles/fonts/FS Me-Bold.otf");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "FS Me";
  src: url("~/src/styles/fonts/FS Me-Bold Italic.otf");
  font-weight: bold;
  font-style: italic;
}
@media (prefers-color-scheme: dark) {
  body {
    --ion-text-color: #c7c7c7;
    --ion-background-color: #1e1e1e;
  }
}
@media (prefers-color-scheme: light) {
  body {
    --ion-text-color: #4c4c4c;
    --ion-background-color: #fcfcfc;
  }
}

a {
  cursor: pointer;
}
a:hover {
  text-decoration: underline;
}

ion-header {
  box-shadow: none !important;
}

.firebaseui-link {
  color: #bdbdbd;
  text-decoration: none;
}